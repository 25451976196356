/*
 * 업무구분   : 청약
 * 화면 명    : MSPPS061P
 * 화면 설명  : 신분증 진위여부확인 팝업
 */
<template>
  <ur-page-container class="msp" title="신분증 진위여부확인" :show-title="true" :topButton="true" type="subpage">

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="row" class="con-area pb132">
        <span class="title must circle fs18rem fwm mt30">신분증 진위여부확인</span>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model.trim="lv_SelectedSeg" class="mt10">
            <mo-segment-button value="lv_Korean">주민등록증</mo-segment-button>
            <mo-segment-button value="lv_Driver">운전면허증</mo-segment-button>
            <mo-segment-button value="lv_Foreigner">외국인등록증</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>

        <div class="w100" v-if="lv_SelectedSeg === 'lv_Korean'">
          <div class="mt50 w100">
            <span class="contain-title ">성명</span>
            <mo-text-field v-model.trim="lv_Korean.custNm" readonly maxlength="6" class="form-input-name full mt10" underline placeholder="성명"/>
          </div>

          <div class="w100 mt50">
            <div class="contain-title">주민등록번호</div>
            <div class="verify-birth mt10">
              <mo-decimal-field v-model.trim="lv_Korean.knbFrno" underline class="birth-input1 form-input-name w100" placeholder="생년월일" error-message=""/>
              <span>-</span>
              <m-trans-key-input v-if="fn_IsMobile" v-model.trim="lv_Korean.knbBkno" ref="korean1" :rules="validateRuleKrFrno" :key="lv_SelectedSeg" type="numberMax7" start="1" end="-1" @mask-type="fn_getMaskType" @masked-txt="fn_SetMaskedTxt" class="birth-input3 form-input-name w100"/>
              <mo-decimal-field v-else v-model.trim="lv_Korean.knbBkno" password numeric mask="#######" ref="korean1" :rules="validateRuleKrFrno" underline class="birth-input3 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●  ●" error-message=""/>
            </div>
          </div>

          <div class="mt50 w100">
            <span class="contain-title ">발급일자</span>
            <!-- <mo-text-field class="form-input-name full mt10"  underline placeholder="발급일자를 입력해주세요." /> -->
            <!-- <mo-date-picker v-model.trim="lv_Korean.idcdIsuDt" ref="korean2" :rules="validateRuleKorIdcdIsuDt" :min-date="lv_MinDate" :max-date="lv_MaxDate" class="form-input-name full mt10 btm-error" underline /> -->
            <mo-decimal-field v-model.trim="lv_Korean.idcdIsuDt" ref="korean2" numeric mask="####-##-##" :rules="validateRuleKorIdcdIsuDt" underline class="form-input-name full mt10 btm-error" placeholder="발급일자를 입력해주세요." />
          </div>
        </div>

        <div class="w100" v-if="lv_SelectedSeg === 'lv_Driver'">
          <div class="mt50 w100">
            <span class="contain-title ">성명</span>
            <mo-text-field v-model.trim="lv_Driver.custNm" readonly maxlength="6" class="form-input-name full mt10" underline placeholder="성명"/>
          </div>

          <div class="w100 mt50">
            <div class="contain-title">주민등록번호</div>
            <div class="verify-birth mt10">
              <mo-decimal-field v-model.trim="lv_Driver.knbFrno" readonly numeric mask="######" underline class="birth-input1 form-input-name w100" placeholder="생년월일" error-message=""/>
              <span>-</span>
              <m-trans-key-input v-if="fn_IsMobile" v-model.trim="lv_Driver.knbBkno" ref="driver1" :rules="validateRuleDriver" :key="lv_SelectedSeg" type="numberMax7" start="1" end="-1" @mask-type="fn_getMaskType" @masked-txt="fn_SetMaskedTxt" class="birth-input3 form-input-name w100"/>
              <mo-decimal-field v-else v-model.trim="lv_Driver.knbBkno" password numeric mask="#######" ref="driver1" :rules="validateRuleDriver" underline class="birth-input3 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●  ●" error-message=""/>
            </div>
          </div>

          <div class="mt50 w100">
            <span class="contain-title ">면허번호</span>
            <div class="verify-birth mt10">
              <mo-decimal-field v-model.trim="lv_Driver.lcnsNo" numeric mask="## - ## - ###### - ##" ref="driver2" :rules="validateRuleLcnsNo" placeholder="00 - 00 - 0000 - 00" underline class="form-input-name minWauto w100" error-message=""/>
              <!-- <mo-decimal-field numeric mask="##" underline class="form-input-name txt-center minWauto w20" placeholder="2자리" error-message=""/>
              <span class="pl10 pr10">-</span> 
              <mo-decimal-field numeric mask="##" underline class="form-input-name txt-center minWauto w20" placeholder="2자리" error-message=""/>
              <span class="pl10 pr10">-</span> 
              <mo-decimal-field numeric mask="######" underline class="form-input-name txt-center minWauto w30" placeholder="6자리" error-message=""/>
              <span class="pl10 pr10">-</span> 
              <mo-decimal-field numeric mask="##" underline class="form-input-name txt-center minWauto w20" placeholder="2자리" error-message=""/> -->
            </div>
            <div class="text-gray-box mt20">
              <div class="fs14rem fwb crTy-bk7">운전면허 지역번호</div>
              <div class="fs14rem crTy-bk7 mt10 break-word">
                서울(11) 부산(12), 경기남부(13), 강원(14), 충북(15), 충남(16), 전북(17), 전남(18), 경북(19), 경남(20), 제주(21), 대구(22), 인천(23), 광주(24), 대전(25), 울산(26), 경기북부(28)
              </div>
            </div>
          </div>
        </div>

        <div class="w100" v-if="lv_SelectedSeg === 'lv_Foreigner'">
          <div class="mt50 w100">
            <span class="contain-title ">성명</span>
            <mo-text-field v-model.trim="lv_Foreigner.custNm" readonly maxlength="30" class="form-input-name full mt10" underline placeholder="성명"/>
          </div>

          <div class="w100 mt50">
            <div class="contain-title">외국인등록번호(거소신고번호)</div>
            <div class="verify-birth mt10">
              <mo-decimal-field v-model.trim="lv_Foreigner.knbFrno" readonly numeric mask="######" underline class="birth-input1 form-input-name w100" placeholder="생년월일" error-message=""/>
              <span>-</span>
              <m-trans-key-input v-if="fn_IsMobile" v-model.trim="lv_Foreigner.knbBkno" ref="foreigner1" :rules="validateRuleFoFrno" :key="lv_SelectedSeg" type="numberMax7" start="1" end="-1" @mask-type="fn_getMaskType" @masked-txt="fn_SetMaskedTxt" class="birth-input3 form-input-name w100"/>
              <mo-decimal-field v-else v-model.trim="lv_Foreigner.knbBkno" password numeric mask="#######" ref="foreigner1" :rules="validateRuleFoFrno" underline class="birth-input3 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●  ●" error-message=""/>
            </div>
          </div>

          <div class="mt50 w100">
            <span class="contain-title ">발급일자</span>
            <!-- <mo-text-field  class="form-input-name full mt10" underline placeholder="발급일자를 입력해주세요." /> -->
            <!-- <mo-date-picker v-model.trim="lv_Foreigner.idcdIsuDt" ref="foreigner2" :rules="validateRuleForIdcdIsuDt" :min-date="lv_MinDate" :max-date="lv_MaxDate" class="form-input-name full mt10 btm-error" underline /> -->
            <mo-decimal-field v-model.trim="lv_Foreigner.idcdIsuDt" ref="foreigner2" numeric mask="####-##-##" :rules="validateRuleForIdcdIsuDt" underline class="form-input-name full mt10 btm-error" placeholder="발급일자를 입력해주세요." />
          </div>

          <div class="mt50 w100">
            <span class="contain-title ">일련번호</span>
            <mo-text-field type="number" v-model="lv_Foreigner.seriaNo" ref="foreigner3" :rules="validateRuleSeriaNo" class="form-input-name w100" placeholder="일련번호(11자리)" mask="###########"/>
          </div>
          <span class="txt">
            <!-- <span class="txt-ment">숫자 @-@@@-@@@-@@@@ 구조, 하이코리아 입력 시 '-' 빼고 숫자만 입력</span><br> -->
            <span class="txt-ment">※ 2011.1.1 이전에 발급된 등록증은 일련번호 입력할 필요 없음(일련번호 없는 경우)</span>
          </span>
        </div>

      </ur-box-container>

    </ur-box-container>
    <!-- Content 영역 end -->
    <template v-if="lv_IsShowButton">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_CheckSsnValidation">확인</mo-button>
        </div>
      </ur-box-container>
    </template>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import moment from 'moment'
import Msg from '~/src/systems/webkit/msg/msg'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPS061P',
  screenId: 'MSPPS061P',
  components: {
    MSPBottomAlert
  },
  // bottom-sheet Full Popup
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    segment: {
      type: [String, Number],
      default: 1
    },
    custNm: {
      type: [String],
      default: ''
    },
    knbFrno: {
      type: [String],
      default: ''
    },
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      this.lv_IsShowButton = false
    })
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      this.lv_IsShowButton = true
    })
    //오토 포커스 추가
    // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영
    if ( this.fn_IsMobile && this.lv_SelectedSeg === 'lv_Korean') {
      this.$refs.korean1.onClick()
    }else if (this.fn_IsMobile && this.lv_SelectedSeg === 'lv_Driver') {
      this.$refs.driver1.onClick()
    }else if (this.fn_IsMobile && this.lv_SelectedSeg === 'lv_Foreigner') {
      this.$refs.foreigner1.onClick()
    }

   
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    this.cntU149 = 0
    return {
      lv_SelectedSeg: this.segment == 1 ? 'lv_Korean'
                    : this.segment == 2 ? 'lv_Driver'
                    : this.segment == 3 ? 'lv_Foreigner'
                    : 'lv_Korean',
      lv_MaxDate: moment().format('YYYY-MM-DD'),
      lv_MinDate: moment().add(-120, 'year').format('YYYY-MM-DD'),
      // 주민등록증
      lv_Korean: {
        custNm: this.custNm, // 성명
        knbFrno: this.knbFrno, // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        idcdIsuDt: '', // 발급일자 (YYYYMMDD)
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
      },
      // 운전면허증
      lv_Driver: {
        custNm: this.custNm, // 성명
        knbFrno: this.knbFrno, // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        lcnsNo: '', // 면허번호
        // lcnsNo1: '', // 면허번호1
        // lcnsNo2: '', // 면허번호2
        // lcnsNo3: '', // 면허번호3
        // lcnsNo4: '', // 면허번호4
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
      },
      // 외국인등록증
      lv_Foreigner: {
        custNm: this.custNm, // 성명
        knbFrno: this.knbFrno, // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        idcdIsuDt: '', // 발급일자 (YYYYMMDD)
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
        seriaNo: '', // 일련번호 11자리
      },
      lv_IsShowButton: true,
      validateRuleKrFrno: [
          (v) => !!v || '필수입니다. 주민등록번호 뒷자리를 입력 하세요.',
          (v) => v.length === 7 || ' 뒷자리를 정확히 입력해 주세요.'
      ],
      validateRuleKorIdcdIsuDt: [
          (v) => !!v || '필수입니다. 발급일자를 입력 하세요.',
          (v) => moment(v, 'YYYYMMDD', true).isValid() || '날짜 형식에 맞지 않습니다.',
          (v) => !moment(v).isAfter(this.lv_MaxDate) || '최대 오늘까지 입력 가능합니다.',
          (v) => !moment(v).isBefore(this.lv_MinDate) || '최소 120년 전까지 입력 가능합니다.'
      ],
      validateRuleFoFrno: [
          (v) => !!v || '필수입니다. 외국인 등록 번호 뒷자리를 입력 하세요.',
          (v) => v.length === 7 || ' 뒷자리를 정확히 입력해 주세요.'
      ],
      validateRuleForIdcdIsuDt: [
          (v) => !!v || '필수입니다. 발급일자를 입력 하세요.',
          (v) => moment(v, 'YYYYMMDD', true).isValid() || '날짜 형식에 맞지 않습니다.',
          (v) => !moment(v).isAfter(this.lv_MaxDate) || '최대 오늘까지 입력 가능합니다.',
          (v) => !moment(v).isBefore(this.lv_MinDate) || '최소 120년 전까지 입력 가능합니다.'
      ],
      validateRuleSeriaNo: [          
          (v) => ((this.lv_Foreigner.idcdIsuDt >= '20110101' && this.lv_Foreigner.seriaNo.length === 11) || this.lv_Foreigner.idcdIsuDt < '20110101') || ' 일련번호를 확인하세요.'
      ],
      validateRuleDriver: [
          (v) => !!v || '필수입니다. 주민등록번호 뒷자리를 입력 하세요.',
          (v) => v.length === 7 || ' 뒷자리를 정확히 입력해 주세요.'
      ],
      validateRuleLcnsNo: [
          (v) => !!v || '필수입니다. 면허번호를 입력하세요.',
          (v) => this.lv_Driver.lcnsNo.length === 12|| ' 면허번호를 정확히 입력해 주세요.'
      ],
      
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_IsMobile() {
      return this.getStore('deviceState').getters.getIsMobile
    },
  },
  watch: {
    lv_SelectedSeg(val) {
      const target = this[val]
      target.knbBkno = ''
      target.knbBknoTxt = ''
      target.knbScCd = ''
      target.knbEncr = ''

      if(val === 'lv_Korean'){
        this.$nextTick(() => {
          if(this.fn_IsMobile && this.lv_Korean.knbBkno === ''){
            // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영
            //this.$refs.korean1.onClick()
          }
        })  
      }else if(val === 'lv_Foreigner'){
        this.$nextTick(() => {
          if(this.fn_IsMobile && this.lv_Foreigner.knbBkno === ''){
            // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영
            //this.$refs.foreigner1.onClick()
          }
        }) 
      }else if(val === 'lv_Driver'){
        this.$nextTick(() => {
          if(this.fn_IsMobile && this.lv_Driver.knbBkno === ''){
            // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영
            //this.$refs.driver1.onClick() 
          }
        }) 
      }

      if(this.$refs['korean1'] != undefined || this.$refs['korean2'] != undefined ){
        this.$refs['korean1'].clearValidation()
        this.$refs['korean2'].clearValidation()
       
      }else if(this.$refs['foreigner1'] != undefined || this.$refs['foreigner2'] != undefined || this.$refs['foreigner3'] != undefined ){
        this.$refs['foreigner1'].clearValidation()
        this.$refs['foreigner2'].clearValidation()
        this.$refs['foreigner3'].clearValidation()
       
      }else if(this.$refs['driver1'] != undefined || this.$refs['driver2'] != undefined ){
        this.$refs['driver1'].clearValidation()
        this.$refs['driver2'].clearValidation()
        
      }
      
    },    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명: fn_getMaskType
    * 설명: ios에서 넘어온 mtranskey key
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_getMaskType (val) {
      const target = this[this.lv_SelectedSeg]
      target.knbScCd = val
    },
    fn_SetMaskedTxt (val) {
      const target = this[this.lv_SelectedSeg]
      target.knbBknoTxt = val
    },
    fnMakeParams (type, tlgmNo) {
      let params = {}
      let trnstId = ''
      const target = this[this.lv_SelectedSeg]

      // this.fnRrnCheckRule(true)
      const {userId: auth} = this.getStore('userInfo').getters.getUserInfo // 컨설턴트 정보

      switch (this.lv_SelectedSeg) {
        // 주민등록증
        case 'lv_Korean':
          if (type === '1') {
            trnstId = 'txTSSBC01P1'
            params = {
              auth,
              cusNm: target.custNm,
              psNo: target.knbEncr,
              idcdIsuDt: moment(target.idcdIsuDt).format('YYYYMMDD')
            }
          } else if (type === '2') {
            trnstId = 'txTSSBC01P2'
            params = {
              auth,
              tlgmNo,
            }
          } else {
            trnstId = 'txTSSBC01P7'
            params = {
              auth,
              tlgmNo,
            }
          }
          break
        // 운전면허증
        case 'lv_Driver':
          if (type === '1') {
            trnstId = 'txTSSBC01P3'
            params = {
              auth,
              cusNm: target.custNm,
              psNo: target.knbEncr,
              lcnsNo: target.lcnsNo.replace(/\D/g, '')
            }
          } else if (type === '2') {
            trnstId = 'txTSSBC01P4'
            params = {
              auth,
              tlgmNo,
            }
          } else {
            trnstId = 'txTSSBC01P7'
            params = {
              auth,
              tlgmNo,
            }
          }
          break
        // 외국인등록증
        case 'lv_Foreigner':
          if (type === '1') {
            trnstId = 'txTSSBC01P5'
            params = {
              auth,
              psNo: target.knbEncr,
              idcdIsuDt: moment(target.idcdIsuDt).format('YYYYMMDD'),
              serialnumber: target.seriaNo // 일련번호
            }
          } else if (type === '2') {
            trnstId = 'txTSSBC01P6'
            params = {
              auth,
              tlgmNo,
              serialnumber: target.seriaNo // 일련번호
            }
          } else {
            trnstId = 'txTSSBC01P7'
            params = {
              auth,
              tlgmNo,
            }
          }
          break
      }
      this.fnServiceCall(params, trnstId, 'S')
    },
    fnServiceCall (params, trnstId, auth) {
      this.post(this, params, trnstId, auth)
        .then((response) => {
          this.fnServiceCallHandler(response)
        })
        .catch((error) => {
          window.vue.error(error)
        })
    },
    fnServiceCallHandler (response) {
      const trnstId = response.trnstComm.trnstId
      const {tlgmNo, code, message} = response.body

      if (code === 'X' || code === 'E') {
        // this.$addSnackbar(code + ': ' + message)
        this.$addSnackbar('신분증 인증에 실패하였습니다.')
        return
      }
      if (trnstId === 'txTSSBC01P1' || trnstId === 'txTSSBC01P3' || trnstId === 'txTSSBC01P5') {
        this.fnMakeParams('2', tlgmNo)
      } else if (trnstId === 'txTSSBC01P2' || trnstId === 'txTSSBC01P4' || trnstId === 'txTSSBC01P6') {
        this.fnMakeParams('3', tlgmNo)
      } else {
        if (message === 'U149' && this.cntU149 < 5) {
          this.cntU149 = this.cntU149 + 1
          this.fnMakeParams('2', tlgmNo)
          // setTimeout(_ => this.fnMakeParams('2', tlgmNo), 500)
        } else {
          this.cntU149 = 0
          // this.scrapYn = code
          if (code === 'Y') {
            // this.getStore('confirm').dispatch('ADD', '신분증 인증에 성공하였습니다.')
            // this.tlgmNo = tlgmNo // 송수신전문관리번호(TTSZ00009) 2021.11.09추가
            // this.checkResult = true
            this.$addSnackbar('신분증 인증에 성공하였습니다.')
            this.$emit('checkResult', true)
          // } else if (code === 'P') { // 권한거부
          //   this.getStore('confirm').dispatch('ADD', '신분증 인증에 실패하였습니다. 카메라 실행권한이 없습니다. 권한을 부여해주세요.')
          // } else if (code === 'E') { // 서비스 에러
          //   this.getStore('confirm').dispatch('ADD', '신분증 인증에 실패하였습니다.')
          } else {
            // 기타 예외
            this.$addSnackbar('진위여부 확인에 실패하였습니다.\n입력 정보를 다시 한번 확인해 주세요.')
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_CheckSsnValidation
    * 설명       : 주민번호 유효성 체크
    ******************************************************************************/
    fn_CheckSsnValidation () {
      const target = this[this.lv_SelectedSeg]

      if(this.lv_SelectedSeg === 'lv_Korean'){

        let tKorean = []
        let tKoreanInput    = []

        for(let i = 1 ; i < 3; i++){
          if(this.fn_IsMobile){
            if(i === 1){
              if(this.$refs.korean1.$data.txtField === '' || this.$refs.korean1.$data.txtField.length !== 7){
                //this.$refs['korean'+i].onClick()
              }
            }
          }else{
            this.$refs['korean'+i].isValid()
          }

          if(this.fn_IsMobile){
            if(i === 1){
              if(this.$refs.korean1.$data.txtField === '' || this.$refs.korean1.$data.txtField.length !== 7){
                tKorean.push(false)
              }
            }else{
              tKorean.push(this.$refs['korean'+i].isValid())
            }
          }else{
            tKorean.push(this.$refs['korean'+i].isValid())
          }
          
          if(this.fn_IsMobile){
            if(i === 1){
              if(this.$refs.korean1.$data.txtField === '' || this.$refs.korean1.$data.txtField.length !== 7){
                  tKoreanInput.push('korean1')
              }
            }else{
              if(!this.$refs['korean2'].isValid()){
                tKoreanInput.push('korean2') 
                }
            }
          }else{
            if(!this.$refs['korean'+i].isValid()){
              tKoreanInput.push('korean'+i)
            }
          }
        }
       

        if(tKoreanInput.length > 0){
          for(let i = tKoreanInput.length-1; i >= 0; i--){  
            this.$nextTick(() => {
              setTimeout(() => {
                if(tKoreanInput[i] === 'korean1'){
                  if(this.fn_IsMobile){
                    let alertMsg = '주민번호 뒷자리를 입력해주시기 바랍니다.'
                    let callbackFnc =  this.$refs.korean1.onClick
                    this.fn_OpenAlert(alertMsg, callbackFnc)
                  }else{             
                    // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영      
                    // this.$refs['korean1'].focus()  
                  }                  
                }
                // else if(tKoreanInput[i] === 'korean2'){
                //   this.$refs['korean2'].focusOn()    
                // }
              }, 300)
            })
          }
        }

        let skorean =  tKorean.some((item) =>
        {      
        if ( !item )
          {          
            return true
          }
        })
        if(skorean) return

      }else if(this.lv_SelectedSeg === 'lv_Foreigner'){

        let tForeigner = []
        let tForeignerInput    = []

        //if(!target.idcdIsuDt !== '' &&  target.idcdIsuDt >= '2011-01-01'){
        if(!((target.idcdIsuDt >= '20110101' && target.seriaNo.length === 11) || (target.idcdIsuDt < '20110101'))){
          for(let i = 1 ; i < 4; i++){
            if(this.fn_IsMobile){
              if(i === 1){
                if(this.$refs.foreigner1.$data.txtField === '' || this.$refs.foreigner1.$data.txtField.length !== 7){
                  //this.$refs['foreigner'+i].onClick()
                }
              }else{
                this.$refs['foreigner'+i].isValid()  
              }
            }else{
              this.$refs['foreigner'+i].isValid()
            }

            if(this.fn_IsMobile){
              if(i === 1){
                if(this.$refs.foreigner1.$data.txtField === '' || this.$refs.foreigner1.$data.txtField.length !== 7){
                    tForeigner.push(false)
                }
              }else{
                tForeigner.push(this.$refs['foreigner'+i].isValid())
              }
            }else{
              tForeigner.push(this.$refs['foreigner'+i].isValid())
            }

            if(this.fn_IsMobile){
              if(i === 1){
                if(this.$refs.foreigner1.$data.txtField === '' || this.$refs.foreigner1.$data.txtField.length !== 7){
                  tForeignerInput.push('foreigner1')
                }
              }else{
                if(!this.$refs['foreigner'+i].isValid()){
                  tForeignerInput.push('foreigner'+i)
                }
              }
            }else{
              if(!this.$refs['foreigner'+i].isValid()){
                tForeignerInput.push('foreigner'+i)
              }
            }
            
          }
        }else{
          for(let i = 1 ; i < 3; i++){
            if(this.fn_IsMobile){
              if(i === 1){
                if(this.$refs.foreigner1.$data.txtField === '' || this.$refs.foreigner1.$data.txtField.length !== 7){
                  //this.$refs['foreigner'+i].onClick()
                }
              }else{
                this.$refs['foreigner'+i].isValid()
              }
            }else{
              this.$refs['foreigner'+i].isValid()
            }

            if(this.fn_IsMobile){
              if(i === 1){
                if(this.$refs.foreigner1.$data.txtField === '' || this.$refs.foreigner1.$data.txtField.length !== 7){
                  tForeigner.push(false)
                }
              }else{
                tForeigner.push(this.$refs['foreigner'+i].isValid())
              }
            }else{
              tForeigner.push(this.$refs['foreigner'+i].isValid())
            }

            if(this.fn_IsMobile){
              if(i === 1){
                if(this.$refs.foreigner1.$data.txtField === '' || this.$refs.foreigner1.$data.txtField.length !== 7){
                  tForeignerInput.push('foreigner1')
                }
              }else{
                if(!this.$refs['foreigner'+i].isValid()){
                  tForeignerInput.push('foreigner'+i)
                }
              }
            }else{
              if(!this.$refs['foreigner'+i].isValid()){
                tForeignerInput.push('foreigner'+i)
              }
            }
            
          }
        }

        if(tForeignerInput.length > 0){
          for(let i = tForeignerInput.length-1; i >= 0; i--){  
            this.$nextTick(() => {
              setTimeout(() => {
                if(tForeignerInput[i] === 'foreigner1'){
                  if(this.fn_IsMobile){
                    let alertMsg = '주민번호 뒷자리를 입력해주시기 바랍니다.'
                    let callbackFnc =  this.$refs.foreigner1.onClick
                    this.fn_OpenAlert(alertMsg, callbackFnc)
                  }else{
                    // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영
                    // this.$refs['foreigner1'].focus()  
                  }                  
                // }else if(tForeignerInput[i] === 'foreigner2'){
                //   this.$refs['foreigner2'].focusOn()    
                }else if(tForeignerInput[i] === 'foreigner3'){
                  this.$refs['foreigner3'].focus()    
                }
              }, 300)
            })
          }
        }

        let sForeigner =  tForeigner.some((item) =>
        {      
        if ( !item )
          {          
            return true
          }
        })
        if(sForeigner) return
        
      }else if(this.lv_SelectedSeg === 'lv_Driver'){        
        let tDriver = [] 
        let tDriverInput    = []
        for(let i = 1 ; i < 3; i++){
          if(this.fn_IsMobile){
            if(i === 1){
              if(this.$refs.driver1.$data.txtField === '' || this.$refs.driver1.$data.txtField.length !== 7){
                //this.$refs['driver'+i].onClick()
              }
            }else{
              this.$refs['driver'+i].isValid()  
            }
          }else{
            this.$refs['driver'+i].isValid()
          }

          if(this.fn_IsMobile){
            if(i === 1){
              if(this.$refs.driver1.$data.txtField === '' || this.$refs.driver1.$data.txtField.length !== 7){
                tDriver.push(false)
              }
            }else{
              tDriver.push(this.$refs['driver'+i].isValid())
            }
          }else{
            tDriver.push(this.$refs['driver'+i].isValid())
          }

          if(this.fn_IsMobile){
            if(i === 1){
              if(this.$refs.driver1.$data.txtField === '' || this.$refs.driver1.$data.txtField.length !== 7){
                tDriverInput.push('driver'+i)
              }
            }else{
              tDriverInput.push('driver'+i)
            }
          }else{
            if(!this.$refs['driver'+i].isValid()){
              tDriverInput.push('driver'+i)
            }
          }
          
        }
        
        if(tDriverInput.length > 0){
          for(let i = tDriverInput.length-1; i >= 0; i--){  
            this.$nextTick(() => {
              setTimeout(() => {
                if(tDriverInput[i] === 'driver1'){
                  if(this.fn_IsMobile){
                    let alertMsg = '주민번호 뒷자리를 입력해주시기 바랍니다.'
                    let callbackFnc =  this.$refs.driver1.onClick
                    this.fn_OpenAlert(alertMsg, callbackFnc)
                  }else{
                    // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영
                    // this.$refs['driver1'].focus()  
                  }                  
                }else if(tDriverInput[i] === 'driver2'){
                  if(tDriverInput.length == 1){
                    this.$refs['driver2'].focus()    
                  }
                }
              }, 300)
            })
          }
        }
        
        let sDriver =  tDriver.some((item) =>
        {      
        if ( !item )
          {          
            return true
          }
        })
        if(sDriver) return
      }

      if(this.$refs['foreigner3'] != undefined ){        
        this.$refs['foreigner3'].clearValidation()
      }

      // PC일 경우 전체 평문 주민번호를 넘겨줌. 모바일일 경우 공백처리
      const knb = this.fn_IsMobile ? '' : (target.knbFrno + '' + target.knbBkno)

      const pParams = {
        knb, // 전체 주민번호
        knbFrno: target.knbFrno, // 주민번호 앞번호
        knbBknoCipher: target.knbBkno, // 주민번호 뒷번호
        knbScCd: target.knbScCd // ios encrypt key
      }

      const trnstId = 'txTSSCM10S6'
      this.post(this, pParams, trnstId, 'S')
        .then(response => {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            if (response.body.ssnYn === 'Y') {
              target.knbEncr = response.body.knbEncr
              this.fnMakeParams('1')
            }
          } else {
            this.$addSnackbar(response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명: fn_OpenAlert
    * 설명: 알럿 함수
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_OpenAlert(alertMsg, callbackFnc) {
      const lv_Vm = this

      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: alertMsg,
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              if (typeof(callbackFnc) === 'function') callbackFnc()
            }
          }
        })
    }
  },
  
};
</script>
<style scoped>
</style>